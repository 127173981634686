import { createReducer, on } from '@ngrx/store';
import {
  addGeozone,
  changeAllGeozonesVisibleOnMap,
  changeGeozoneGroupsToVisibleOnMap,
  changeGeozoneToVisibleOnMap,
  deleteGeozoneById,
  hardChangeGeozoneToVisibleOnMap,
  initGeozonesVisibleOnMap,
  setLastPageStateGeozones,
  storeGeozones,
  updateGeozoneById,
} from '../actions/geozones.actions';
import { AvailableTabsOnPage, LastPageStateWithTabs } from '@store/types/basic-last-page-state';
import { SortOrder } from '@common/utils/search-sort';

export type GeozonesState = {
  list: any[];
  lastPageState: LastPageStateWithTabs;
  visibleOnMap: { [key: number]: boolean };
};

export const initialStateGeozones: GeozonesState = {
  list: [],
  lastPageState: {
    activeTabOnPage: AvailableTabsOnPage.List,
    [AvailableTabsOnPage.List]: {
      searchValue: '',
      sortOrder: SortOrder.Asc,
    },
    [AvailableTabsOnPage.Groups]: {
      searchValue: '',
      sortOrder: SortOrder.Asc,
      openedGroupId: null,
      isOpenedFreeList: false,
    },
  },
  visibleOnMap: {},
};

const compareKeys = (obj1: { [key: number]: any }, obj2: { [key: number]: any }) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  const set2 = new Set(keys2);

  for (const key of keys1) {
    if (!set2.has(key)) return false;
  }

  return true;
};

export const geozonesReducer = createReducer(
  initialStateGeozones,
  on(storeGeozones, (state, { list }) => ({ ...state, list })),

  on(setLastPageStateGeozones, (state, { newPageState }) => {
    return {
      ...state,
      lastPageState: {
        ...state.lastPageState,
        ...newPageState,
      },
    };
  }),

  on(updateGeozoneById, (state, data) => {
    const { updatedGeozone, params } = data;
    const list = state.list.map((geozone: any) => {
      return geozone.id === updatedGeozone.id ? { ...geozone, ...updatedGeozone } : geozone;
    });

    return { ...state, list };
  }),

  on(addGeozone, (state, data) => {
    const { createdGeozone, params } = data;
    const existingcreatedGeozone = state.list.find((obj: any) => obj.id === createdGeozone.id);

    if (!existingcreatedGeozone) {
      const newList = [createdGeozone, ...state.list];
      return {
        ...state,
        list: newList,
      };
    }

    return state;
  }),
  on(deleteGeozoneById, (state, data) => {
    const { deletedGeozoneId } = data;
    return {
      ...state,
      list: state.list.filter((geozone: any) => geozone.id !== deletedGeozoneId),
    };
  }),

  on(initGeozonesVisibleOnMap, (state, data) => {
    const { visibleOnMap } = data;

    if (compareKeys(state.visibleOnMap, visibleOnMap)) {
      return state;
    }

    return {
      ...state,
      visibleOnMap,
    };
  }),

  on(hardChangeGeozoneToVisibleOnMap, (state, data) => {
    const { geozoneId, isVisible } = data;
    const currentVisibleOnMap = { ...state.visibleOnMap };

    if (isVisible) {
      currentVisibleOnMap[geozoneId] = true;
    } else {
      delete currentVisibleOnMap[geozoneId];
    }

    return {
      ...state,
      visibleOnMap: currentVisibleOnMap,
    };
  }),

  on(changeGeozoneToVisibleOnMap, (state, data) => {
    const { geozoneId } = data;
    const currentVisibleOnMap = { ...state.visibleOnMap };

    if (geozoneId in currentVisibleOnMap) {
      delete currentVisibleOnMap[geozoneId];
    } else {
      currentVisibleOnMap[geozoneId] = true;
    }

    return {
      ...state,
      visibleOnMap: currentVisibleOnMap,
    };
  }),
  on(changeGeozoneGroupsToVisibleOnMap, (state, data) => {
    const { ids, stateAll } = data;
    const currentVisibleOnMap = { ...state.visibleOnMap };

    ids.forEach((key: number) => {
      if (stateAll) {
        currentVisibleOnMap[key] = true;
      } else {
        delete currentVisibleOnMap[key];
      }
    });
    return {
      ...state,
      visibleOnMap: currentVisibleOnMap,
    };
  }),

  on(changeAllGeozonesVisibleOnMap, (state, data) => {
    const { stateAll } = data;
    let currentVisibleOnMap: { [key: number]: boolean } = {};

    if (stateAll) {
      currentVisibleOnMap = state.list.reduce((acc, item) => {
        acc[item.id] = true;
        return acc;
      }, {});
    }

    return {
      ...state,
      visibleOnMap: currentVisibleOnMap,
    };
  }),
);
