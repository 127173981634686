import { createAction, props } from '@ngrx/store';
import { LastPageStateWithTabs } from '@store/types/basic-last-page-state';

export const getGeozones = createAction('[Geozones] Get');
export const storeGeozones = createAction('[Geozones] Store', props<{ list: any }>());
export const updateGeozoneById = createAction(
  '[Geozones] Update geozone by id',
  props<{ updatedGeozone: any; params?: any }>(),
);

export const setLastPageStateGeozones = createAction(
  '[stateGeozones] Set last page state',
  props<{ newPageState: Partial<LastPageStateWithTabs> }>(),
);

export const deleteGeozoneById = createAction(
  '[Geozones] Delete geozone by id',
  props<{ deletedGeozoneId: any }>(),
);
export const addGeozone = createAction(
  '[Geozones] Add new geozone',
  props<{ createdGeozone: any; params?: any }>(),
);

export const initGeozonesVisibleOnMap = createAction(
  '[Geozones] Add geozones to visible on map',
  props<any>(),
);
export const changeGeozoneToVisibleOnMap = createAction(
  '[Geozones] Change visible geozones on map',
  props<{ geozoneId: number }>(),
);

export const hardChangeGeozoneToVisibleOnMap = createAction(
  '[Geozones] Hard change visible geozones on map',
  props<{ geozoneId: number; isVisible: boolean }>(),
);

export const changeGeozoneGroupsToVisibleOnMap = createAction(
  '[Geozones] Change visible geozone groups on map',
  props<{ stateAll: boolean; ids: number[] }>(),
);
export const changeAllGeozonesVisibleOnMap = createAction(
  '[Geozones] Change visible all geozones on map',
  props<{ stateAll: boolean }>(),
);
