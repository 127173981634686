import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { GeozonesService } from '@services/geozones.service';
import { getGeozones, storeGeozones } from '../actions/geozones.actions';
import { getSortedList, SortOrder } from '@common/utils/search-sort';

@Injectable()
export class GeozonesEffects {
  getGeozones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGeozones),
      exhaustMap(() =>
        this.geozonesService.getGeozonesList().pipe(
          map((geozones: any) => {
            return storeGeozones({ list: getSortedList(geozones, SortOrder.Asc) });
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private geozonesService: GeozonesService,
  ) {}
}
