import { createAction, props } from '@ngrx/store';
import { LastPageStateWithTabs } from '@store/types/basic-last-page-state';

export const setLoaded = createAction('[Objects] Set Loaded', props<{ loaded: boolean }>());

export const setLastPageStateObjects = createAction(
  '[stateObjects] Set last page state',
  props<{ newPageState: Partial<LastPageStateWithTabs> }>(),
);

export const getObjects = createAction('[Objects] Get');

export const setTrackedObjectId = createAction(
  '[Objects] Set tracking object id',
  props<{
    objectId: number;
  }>(),
);
export const stopTrackedAllObjects = createAction('[Objects] Stop tracking all objects');

export const storeObjects = createAction('[Objects] Store', props<{ list: any }>());
export const updateLastMessageForObjects = createAction(
  '[Objects] Update last message',
  props<any>(),
);

export const updateConnectedStatusForObject = createAction(
  '[Objects] Update connected status',
  props<any>(),
);

export const updateObjectById = createAction(
  '[Objects] Update object by id',
  props<{ updatedObject: any }>(),
);

export const deleteObjectById = createAction(
  '[Objects] Delete object by id',
  props<{ deletedObjectId: any }>(),
);
export const addObject = createAction('[Objects] Add new object', props<any>());
